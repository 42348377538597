<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixMediaFieldInterface } from '@voix/types'
import type { VoixEventsFieldInterface } from '~/fields/events/types.d'

// Config
const props = defineProps<{
  fields: {
    event: VoixEventsFieldInterface
    image: VoixMediaFieldInterface
    imageRight: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Events Feature Card',
    group: 'Events',
    layouts: ['Atlantisbahamas'],
  },
  preview: 'SlicesGlueEventsFeatureCard.jpg',
  description: 'Events Feature Card',
  fields: {
    event: {
      label: 'Event',
      type: 'events',
      description: 'Select the event to display.',
    },
    image: {
      label: 'Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 600,
          height: 400,
        },
        md: {
          width: 1200,
          height: 800,
        },
      },
    },
    imageRight: {
      label: 'Image Right',
      type: 'checkbox',
      description: 'Align the image to the right.',
      default: false,
    },
  },
  templates: [{
    label: 'Events Feature Card',
    fields: {
      event: {
        type: 'events',
        value: {
          id: '123',
          data: {
            title: 'Event Title',
            venue: 'Event Venue',
            content: '<p>Event Content</p>',
            image: {
              url: 'https://atlantis-cms-media.s3.us-east-2.amazonaws.com/files/2024/12/Teacup-358x245.jpg',
            },
            card_dates: [
              {
                date: 'January 1, 2022',
                url: 'https://www.atlantisbahamas.com/events/2022/01/event',
              },
              {
                date: 'January 2, 2022',
                url: 'https://www.atlantisbahamas.com/events/2022/01/event',
              },
            ],
          },
        },
      },
    },
  }],
})

// State
const event = ref<any>(props.fields.event.value?.data)
const eventDates = ref<{ date: string, url: string }[]>([])
const showDates = ref(false)

// Computed
const eventId = computed(() => props.fields.event.value?.id ? props.fields.event.value?.id : -1)

// Methods
async function getEventDates() {
  return $voixFetch(`/api/atlantis/event-cards/${props.fields.event.value?.id}`)
    .then((response) => {
      event.value = response
      eventDates.value = response.card_dates
    })
}
function goToEventUrl(url: string) {
  window.location.href = url
}

// Watchers
watch(eventId, () => {
  getEventDates()
})

// Lifecycle
onMounted(() => {
  if (props.fields.event.value?.id)
    getEventDates()
})
</script>

<template>
  <div
    class="container flex flex-col bg-white"
    :class="{
      'lg:flex-row': !fields.imageRight.value,
      'lg:flex-row-reverse': fields.imageRight.value,
    }"
  >
    <!-- Image -->
    <VoixMedia v-if="fields.image.value.length > 0" :field="fields.image" class="w-full lg:w-[70%] object-cover" />
    <VoixMedia v-else-if="event?.image" :field="event?.image" class="w-full lg:w-[70%] object-cover" />
    <div v-else class="w-full min-h-[350px] lg:min-h-[500px] lg:w-[70%] bg-gray-300" />

    <!-- Content -->
    <div class="flex flex-col max-w-[100%] lg:max-w-[30%] px-12">
      <!-- Title & Copy -->
      <div class="py-8">
        <div class="w-54 mb-6 border-b border-gray-300">
          <div class="uppercase font-sans font-light leading-snug text-gray-800 text-3xl">
            {{ event?.title }}
          </div>

          <div class="font-serif font-light text-red-500 italic mb-4 text-2xl tracking-wider">
            {{ event?.card_venue }}
          </div>
        </div>

        <div
          class="text-gray-500 font-sans2 text-15 leading-relaxed wysiwyg"
          v-html="event?.content"
        />
      </div>

      <!-- CTA -->
      <div class="flex flex-col items-start space-y-4 mt-8 pb-8">
        <div class="flex space-x-6">
          <!-- Single Date -->
          <div
            v-if="eventDates.length === 1"
            class="btn-ghost w-full cursor-pointer"
            @click="goToEventUrl(eventDates[0].url)"
          >
            Join Us {{ eventDates[0].date }}
          </div>

          <!-- Multiple Dates -->
          <div
            v-if="eventDates.length > 1"
            class="btn-ghost min-w-[200px] relative cursor-pointer"
            @mouseover="showDates = true"
            @mouseleave="showDates = false"
          >
            <span class="z-10">Book Now</span>

            <div
              class="absolute top-[51px] left-0 max-h-60 overflow-auto w-full duration-300 z-20"
              :class="{
                'opacity-100': showDates,
                'opacity-0': !showDates,
              }"
            >
              <div
                v-for="(eventDate, index) in eventDates"
                :key="index"
                class="w-full border-x border-b border-gray-300 cursor-pointer bg-white hover:bg-gray-100"
                @click="goToEventUrl(eventDates[index].url)"
              >
                <div class="p-4">
                  {{ eventDate.date }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
